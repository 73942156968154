import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import { BehaviorSubject } from 'rxjs';



@Injectable({
    providedIn: 'root'
})

export class CaracteristiqueService extends BaseService {
    private params = { type: null, formation_id: 0, title: '' };
    paramBehaviourSubject = new BehaviorSubject(this.params);

    constructor() {
        super('caracteristique');
    }

    start(type: string, formation_id: number, title:string) {
        this.params.formation_id = formation_id;
        this.params.type = type;
        this.params.title = title;
        this.paramBehaviourSubject.next(this.params);
    }

}