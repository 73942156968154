import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, Form } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-snotify';
import * as serverInfo from '../../services/server';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.sass']
})
export class FooterComponent implements OnInit {

  forms: FormGroup;
  requesting: boolean;
 
  constructor( private formBuilder: FormBuilder, public http: HttpClient, public snotify: SnotifyService ) {

  }

  ngOnInit() {

    this.initAddForm();
  }


  initAddForm() {
    this.forms = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    })
  }

  notify(type = "info", message: string) {    
    this.snotify.clear();
    this.snotify[type](message, {
      timeout: 2000,
      showProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true
    });

  }

  add() {
    const formValue = this.forms.value;
    this.requesting = true;

    this.http.post(serverInfo.Server.baseUrl+'newsletter', formValue).subscribe(
      (res: any) => {
        if (res.success) {
          this.notify("success", "Merci de vous êtes abonné");
          this.initAddForm();
          this.requesting = false;
        }
        else {
          this.notify('error', 'cette adresse email fait déjà parti de nos abonnés');
          this.initAddForm();
          this.requesting = false;
        }
      },
      (error) => {
        this.notify('error', 'cette adresse email fait déjà parti de nos abonnés');
        this.requesting = false;
      }
    );
  }
}

