import { Injectable } from "@angular/core";
import { Subject, BehaviorSubject } from "rxjs";
import { BaseService } from '../base.service';

@Injectable({
    providedIn: 'root'
})

export class AccountService extends BaseService {

    private authState: boolean = true;
    public profile = new BehaviorSubject<any>(null);

    //say if we alreday printed a welcom message to a user
    public welcomedUser = false;

    constructor() {
        super('');
    }


    isAuth(): boolean {
        return this.authState;
    }

    setAuth(state: boolean){
        this.authState = state;
    }

    logout() {
        this.get('logout').subscribe(
            (res: any) => {
                if (res.success) {
                    localStorage.removeItem('token');
                }
            },
            (error) => {
                this.notify('error', "serverError");
            }
        )
    }

}