import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { FormBuilder, FormGroup, Validators, Form } from "@angular/forms";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import * as serverInfo from "./services/server";

import {
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from "@angular/router";

declare var $: any;
declare var Swiper: any;
declare var jQuery: any;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  private menu: any[] = [];
  private publicMenu: any[];
  private adminMenu: any[];
  private profile = { userType: 1 };
  loading = false;
  domaines = [];
  certifications = [];
  keyword: string = null;
  constructor(
    public translate: TranslateService,
    public router: Router,
    public http: HttpClient
  ) {
    this.translate.setDefaultLang("fr");
    this.translate.use("fr");

    this.router.events.subscribe((event: Event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.loading = true;
          break;
        }

        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.loading = false;
          break;
        }
        default: {
          break;
        }
      }
    });
  }

  ngOnInit() {
    if (this.profile) {
      if (this.profile.userType == 1) {
        this.menu = this.adminMenu;
      } else {
        this.menu = this.publicMenu;
      }
    }

    // this.updateRapport('updated', 'updated_2')

    this.http
      .get(serverInfo.Server.baseUrl + "domaine/get-all")
      .subscribe((res: any) => {
        if (res.data) {
          this.domaines = res.data;
        }
      });

    this.http
      .get(serverInfo.Server.baseUrl + "certification/get-all")
      .subscribe((res: any) => {
        if (res.data) {
          this.certifications = res.data;
        }
      });
  }

  OnInput(event: any) {
    this.keyword = event.target.value;
  }

  search() {
    if (!/^ *$/.test(this.keyword) && this.keyword.length) {
      $("#modal__search").modal("toggle");
      this.router.navigate(["/formation/search/" + this.keyword]);
    }
  }

  private updateRapport(oldKey: string, newKey: string) {
    localStorage.getItem(oldKey) ? localStorage.removeItem(oldKey) : null;

    if (!localStorage.getItem(newKey)) {
      localStorage.setItem(newKey, JSON.stringify({ value: true }));
      $("#modal__update").modal("toggle");
    }
  }
}
