import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
// set locale language
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData, DatePipe } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
// the second parameter 'fr' is optional
registerLocaleData(localeFr, 'fr');

// import ngx-translate and the http loader
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-snotify';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
// PROVIDERS
import { AppInjector } from './services/injector';
import { AccountGuard } from './services/account/accountGuard.service';
import { BaseService } from './services/base.service';
import { UserService } from './services/account/user.service';
import { AccountService } from './services/account/account.service';
import { ConfigService } from './services/config.service';
import { PageService } from './services/page.service';
import { AppService } from './services/app.service';
import { PaysService } from './services/pays.service';
import { VilleService } from './services/ville.service';
import { DomaineService } from './services/domaine.service';
import { CertificationService } from './services/certification.service';
import { NewsletterService } from './services/newsletter.service';
import { FaqService } from './services/faq.service';
import { CategorieService } from './services/categorie.service';
import { FormationService } from './services/formation.service';
import { CaracteristiqueService } from './services/caracteristique.service';
import { FileManagerService } from './shared-module/file-manager/file-manager.service';
import { ActualiteService } from './services/actualite.service';
import { SessionService } from './services/session.service';
import { FooterComponent } from './components/footer/footer.component';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    SnotifyModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'fr' },
    { provide: 'SnotifyToastConfig', useValue: ToastDefaults },
    AppInjector,
    SnotifyService,
    AccountGuard, PageService,
    UserService, AccountService,PaysService,
    VilleService,DomaineService, CertificationService,
    NewsletterService, FaqService, CategorieService, FormationService,
    CaracteristiqueService, FileManagerService,
    ActualiteService, SessionService, NewsletterService, DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

// required for AOT compilation FOR ngx-translate
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}