import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccountGuard } from './services/account/accountGuard.service';
import { AboutComponent } from './components/about/about/about.component';

// canActivate: [AccountGuard], data: { action: 'logged' },
const routes: Routes = [
  {
    path: '',
    loadChildren: './components/home/home.module#HomeModule'
  },
  {
    path: 'contact',
    loadChildren: './components/contact/contact.module#ContactModule'
  },
  {
    path: 'devis',
    loadChildren: './components/devis/devis.module#DevisModule'
  },

  {
    path: 'blog',
    loadChildren: './components/blog/blog.module#BlogModule'
  },
  {
    path: 'about',
    loadChildren: './components/about/about.module#AboutModule'
  },
  {
    path: 'formation',
    loadChildren: './components/formation/formation.module#FormationModule'
  },
  {
    path: 'localisation',
    loadChildren: './components/localisation/localisation.module#LocalisationModule'
  },

  {
    path: 'account',
    loadChildren: './components/account/account.module#AccountModule'
  },
  // {
  //   path: 'user',
  //   loadChildren: './components/user/user.module#UserModule'
  // },

  {
    path: '**',
    loadChildren: './components/not-found/not-found.module#NotFoundModule'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', scrollPositionRestoration: 'top' })

  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
