export const Server = {
    baseUrl: 'https://growthcontinue.com/server/public/api/',
    domaineUrl: 'https://growthcontinue.com/',
    serverFilePath: 'https://growthcontinue.com/server/storage/app/public/'
}

// export const Server = {
//     baseUrl: 'http://localhost:8000/api/',
//     domaineUrl: 'http://localhost:8000/',
//     serverFilePath: 'http://localhost:8000/storage/'
// }
